@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/tooltip";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "assets/tiny-slider.css";
// @import "bootstrap/scss/bootstrap";

@font-face {
    font-family: 'Hand Of Sean';
    src: url('assets/fonts/hand-of-sean-demo-webfont.eot');
    src: url('assets/fonts/hand-of-sean-demo-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/hand-of-sean-demo-webfont.woff2') format('woff2'),
         url('assets/fonts/hand-of-sean-demo-webfont.woff') format('woff'),
         url('assets/fonts/hand-of-sean-demo-webfont.svg#hand_of_sean_demoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}  
@font-face {
    font-family: 'Sacramento';
    src: url('assets/fonts/sacramento-regular-webfont.eot');
    src: url('assets/fonts/sacramento-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/sacramento-regular-webfont.woff2') format('woff2'),
         url('assets/fonts/sacramento-regular-webfont.woff') format('woff'),
         url('assets/fonts/sacramento-regular-webfont.ttf') format('truetype'),
         url('assets/fonts/sacramento-regular-webfont.svg#sacramentoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: "FreestyleScript";
    src: url("assets/fonts/FREESCPT.eot"); /* IE9 Compat Modes */
    src: url("assets/fonts/FREESCPT.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url("assets/fonts/FREESCPT.svg") format("svg"), /* Legacy iOS */
        url("assets/fonts/FREESCPT.ttf") format("truetype"), /* Safari, Android, iOS */
        url("assets/fonts/FREESCPT.woff") format("woff"), /* Modern Browsers */
        url("assets/fonts/FREESCPT.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFUITextRegular';
    src: url('assets/fonts/SFUITextRegular.eot');
    src: url('assets/fonts/SFUITextRegular.eot') format('embedded-opentype'),
         url('assets/fonts/SFUITextRegular.woff2') format('woff2'),
         url('assets/fonts/SFUITextRegular.woff') format('woff'),
         url('assets/fonts/SFUITextRegular.ttf') format('truetype'),
         url('assets/fonts/SFUITextRegular.svg#SFUITextRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFProDisplayRegular';
    src: url('assets/fonts/SFProDisplayRegular.eot');
    src: url('assets/fonts/SFProDisplayRegular.eot') format('embedded-opentype'),
         url('assets/fonts/SFProDisplayRegular.woff2') format('woff2'),
         url('assets/fonts/SFProDisplayRegular.woff') format('woff'),
         url('assets/fonts/SFProDisplayRegular.ttf') format('truetype'),
         url('assets/fonts/SFProDisplayRegular.svg#SFProDisplayRegular') format('svg');
}
@font-face {
    font-family: 'SFProDisplayBold';
    src: url('assets/fonts/SFProDisplayBold.eot');
    src: url('assets/fonts/SFProDisplayBold.eot') format('embedded-opentype'),
         url('assets/fonts/SFProDisplayBold.woff2') format('woff2'),
         url('assets/fonts/SFProDisplayBold.woff') format('woff'),
         url('assets/fonts/SFProDisplayBold.ttf') format('truetype'),
         url('assets/fonts/SFProDisplayBold.svg#SFProDisplayBold') format('svg');
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('assets/fonts/SFProDisplay-Medium.eot');
    src: url('assets/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('assets/fonts/SFProDisplay-Medium.woff') format('woff'),
        url('assets/fonts/SFProDisplay-Medium.ttf') format('truetype'),
        url('assets/fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}
html {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    overflow-x: hidden !important;
}
body {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    color: #343434;
    font-weight: 400;
    overflow-x: hidden;
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
}
*{
    margin:0px;
    padding: 0px;
    box-sizing: content-box;
}
.wrapper {
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
}
.subpatientform-section {
    background-color: #fff;
    padding: 40px 0;
    p, span, label, h1,h2, h3, h4, h5, strong {
        word-break: break-word;
    }
    label {
        position: relative; // https://app.asana.com/0/907870129842460/1207444210863476/f
    }
}
.p-24 {
    padding: 24px !important;
}
pre {
    white-space: pre-wrap;
    word-break: break-word;
    margin-top: 0;
    font-size: 16px;
    color: #343434;
    font-weight: 500;
    font-family: 'Roboto', sans-serif !important;
}

[type="checkbox"], [type="radio"]{
    width: auto;
}
.ui-datepicker {
    width: 20em;
    padding: 0;
    display: none;
}
.ui-datepicker, .eventCalOut, .calendarIcon {
    background-color: #ffffff;
    border: 1px solid #58595b;
}
.ui-datepicker {
    z-index: 10 !important;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 10px;
}
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
    border: 0 none;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-prev:hover {
    left: 0;
}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -7px;
    top: 50%;
    margin-top: -8px;
    font-size: 12px;
}
.ui-datepicker .ui-datepicker-prev:after {
    content: '';
    position: absolute;
    top: 5px;
    width: 0;
    height: 0;
    left: 8px;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #58595b transparent transparent;
}
.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-next:hover {
    right: 0;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    text-align: left;
    text-indent: -999em;
    overflow: hidden;
    background: none;
    padding: 0;
    margin-top: -15px;
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
    width: 45%;
    margin: 0 5px;
    float: left;
    border: 1px solid #58595b;
}
tr {
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

input{
    height:30px !important;
    margin-bottom: 18px;
    font-weight: 600;
    color: #343434;
    font-size: 16px;
    border-color: #343434 !important;
    border-bottom: 1px solid #343434 !important;
    padding-right: 25px;
    box-sizing: border-box;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:focus{
        box-shadow: 0 1px 0 0 #939598 !important;
    }
}
.dropdown-content li>span>label {
    font-weight: 500;
    color: #343434;
}
[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before, [type="radio"].with-gap:checked + span:after{
    border: 2px solid #F28820 !important;
}
[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:after{
    background: #F28820 !important;
}
[type="radio"]:not(:checked) + span:before, [type="radio"]:not(:checked) + span:after{
    border: 2px solid #939598 !important;
}

[type="checkbox"]:checked + span:not(.lever):before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #58595b !important;
    border-bottom: 2px solid #58595b !important;
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-origin: 100% 100%;
}
.select-wrapper * {
    transition: none !important;
    transform: none !important;
}
.dropdown-content{
    overflow: hidden !important;
}
.input-field.custminput-field > label {
    position: relative;
    white-space: normal;
    font-weight: 500;
    color: #343434;
    font-size: 14px;
    //font-size: 14px; //new changes font size
    word-break: break-word;
    transform:inherit !important;
    line-height: normal !important;
}
.subpatientqueredio label{
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #343434;
    word-break: break-word;
}
.subpatientqueCustom label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #343434;
    word-break: break-word;
}

.subpatientqueCustom .col {
    margin-right: 30px;
}

.subpatientqueCustom .col:last-child {
    margin-right: 0;
}

.subpatientqueCustom .col span {
    width: 250px;
}
.inputformplaceholder {
    font-size: 14px;
    padding-bottom: 8px;
    display: block;
    line-height:48px !important;
    font-weight: 500;
    color: #343434;
}
.input-field > label:not(.label-icon).active{
    transform: inherit !important;
    transform-origin: 0 0;
}
.input-field {
    position: relative;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.row {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
}
.modal{
    height:65%;
    top: 50% !important;
    transform: translateY(-50%);
    .modal-content{
        padding:0px !important;
    }
    .modal-footer {
        height: auto !important;
        padding: 0px !important;
        border: none !important;
        margin-top: 23px;
        a {
            margin: 0px !important;
        }
    }
}
.signbtnpopup .signimg {
    height: 150px;
}
.reqinvalid {
    box-shadow: 0 1px 4px rgb(244 137 37 / 88%), 0 0 2px rgb(244 137 37 / 80%);
    // padding: 0 4px;
    padding: 4px; //new changes padding css
    border: 1px solid rgb(244 137 37 / 88%);
}
.helper-text{
    color:#F44336 !important;
    font-weight: 400;
    
}
button, input, optgroup, select, textarea{
    font-family: 'Work Sans', 'Arial', sans-serif !important;
}

.spacetop > span {
    font-size: 16px;
    padding-bottom: 8px;
    display: block;
    line-height: 1.1;
    font-weight: 500;
    color: #343434;
}



.personal-box-line{
    border-bottom:1px solid #000;
    /* width:100%; */
}
.personal-block{
    border:1px solid #000;
    padding:30px;
}
.personal-box label{
    color: #000;
    display:inline-block;
    padding-left:5px;
}
.personal-input-box input {
    line-height: normal;
    margin: 0 !important;
    position: relative;
    top: -6px;
    height: 16px !important;
}

.personal-box{
    display:flex;
    margin-bottom:25px;
}
.personal-input{
    width:25%;
}
.personal-input-box{
    position: relative;
}

.personal-box span{
    position: absolute;
    left:90px;
    bottom:-20px;
}
.personal-input-box input[type="text"] {
    -webkit-box-shadow: none;
    font-size:14px;
    height:20px;
    border:none;
    padding:-1px 15px;
    border-bottom:1px solid #000;
    box-shadow: none;
    width:100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.personal-radio-box{
    display: flex;
    width:8.5%;
}
.personal-radio-box label {
    line-height: 18px;
    vertical-align: top;
}
.personal-radio-box input{
    height: auto !important;
    border: none !important;
    box-shadow: none !important;
}
.personal-second-box{
    display:flex;
    margin-bottom:25px;
}
.personal-dropdown{
    display: flex;
    width:15%;
}
.personal-dropdown select{
    width:100%;
}
.personal-dropdown label{
    padding-right:10px;
}
.personal-dropdown select {
    width: 100%;
    height: 20px;
    padding: 0 5px;
}
.personal-box [type="checkbox"]:not(:checked), .personal-box [type="checkbox"]:checked{
    top: -3px;
    opacity:1;
    pointer-events:painted;
}
// .opendental .wrapper {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     margin: 0 auto;
// }
.opendental {
    display: flex;
}
.opendental .parentDiv {
    position: relative;
    // left: 28%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;
    // height: 600px;
    // overflow: hidden;
    // width: 100%;
    // overflow-y: auto;
    
}
.opendental .wrapper:nth-child(2n) {
    position: absolute;
    top: calc(100vh - -228px);
    height: 30px;
}
.subpatientform-section-opendental {
    padding: 40px 0;
    // height: 100% !important;
}
// .subpatientform-section-opendental
pre {
    white-space: pre-wrap;
    word-break: break-word;
    margin-top: 10px;
    font-family: 'Work Sans', 'Arial', sans-serif;
    font-size: 14px !important;
    color: #343434 !important;
    font-weight: 400 !important;
}
ul.dropdown-content.select-dropdown{
    max-height: 95px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 100% !important;
}
.select-wrapper .select-dropdown.dropdown-content li.selected span{
    font-weight: 500 !important;
    color: #F28820 !important;
}
.select-wrapper .select-dropdown.dropdown-content li span{
    color: #343434 !important;
    white-space: normal;
    word-wrap: break-word;
}
.select-wrapper {
    .select-dropdown.dropdown-content {
        li {
            &:hover {
                span {
                    color: #F28820 !important;
                }
            } 
        }
    }
}
.subpatientfieldcol .spacetop {
    margin:10px 0 0 0;
}
.subpatientfieldcol .input-field {
    margin: 10px 0 15px 0 !important;
}
.signbelow {
    animation: shake-animation 2s ease infinite;
    transform-origin: 90% 90%;
    cursor: pointer;
}
@keyframes shake-animation {
    0% { transform:translate(0,0) }
   1.78571% { transform:translate(5px,0) }
   3.57143% { transform:translate(0,0) }
   5.35714% { transform:translate(5px,0) }
   7.14286% { transform:translate(0,0) }
   8.92857% { transform:translate(5px,0) }
   10.71429% { transform:translate(0,0) }
   100% { transform:translate(0,0) }
 }
// input[type=text]:not(.browser-default){
//     margin-bottom:18px !important;
// }
.modal{
    top:10%;
    height: auto !important;
    max-height: initial !important;
}
.modal-dialog {
    max-width: 100% !important;
    border: none !important;
    margin: 0px !important;
}
.modal .modal-content {
    border: none !important;
    border-radius: 0px !important;
}
.modal .modal-footer{
    justify-content: space-between;
    position: relative;
    bottom: 0;
    background: none !important;
}
.datepicker-modal{
    background: none !important;
    box-shadow: none !important;
}
input[type=text]:not(.browser-default):disabled {
    color: #343434 !important;
    border-bottom: 1px dotted rgba(0,0,0,0.42) !important;
}
.modal.show{
    display: block !important;
}
.confirmation-btns .waves-ripple {
    display: none;
}
.confirmation-btns button.btn-flat.timepicker-close.waves-effect:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}
.medication-name-option {
    mat-pseudo-checkbox {
        display: none;
    }
    .mdc-list-item__primary-text {
        color: #343434 !important;
    }
}
.input-field.custminput-field .input-field input {
    box-sizing: border-box;
    padding-right: 15px;
}
.input-field.custminput-field .input-field .dropdown-menu {
    display: inline-block;
    width: calc(100% - 1.5rem);
    box-sizing: border-box;
    transform: unset !important;
    inset: 30px auto auto 0.75rem !important;
    border: 0;
    border-radius: 2px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
}
.input-field.custminput-field .input-field .dropdown-menu .dropdown-item {
    margin: 0;
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    padding: 7px 10px;
    background-color: #fff;
    white-space: unset;
    cursor: pointer;
}
.input-field.custminput-field .input-field .dropdown-menu .dropdown-item.active {
    background-color: #F2F2F2;
}
.custminput-field input {
    font-weight: 500;
    cursor: pointer;
}
.custminput-field input::-webkit-input-placeholder {
    color: #343434;
    opacity: 1;
}
.custminput-field input::-moz-placeholder {
    color: #343434;
    opacity: 1;
}
.custminput-field input:-ms-input-placeholder {
    color: #343434;
    opacity: 1;
}
.custminput-field input:-moz-placeholder {
    color: #343434;
    opacity: 1;
}
.custminput-field .s1 .material-icons {
    margin-top: 9px;
}
#ngb-live {
    display: none !important;
}
.input-field.custminput-field {
    .input-field.medication-input-field {
        .dropdown-menu {
            max-height: 270px;
            overflow-y: auto;
            overflow-x: hidden;
            .dropdown-item {
                padding:10px;
            }
        }
    }
}


.ui-datepicker {z-index: 10 !important;}
.ui-datepicker, .eventCalOut, .calendarIcon {background-color: #ffffff; border: 1px solid #58595b;}
.ui-datepicker th {background-color:#58595b; color: #ffffff;}
.ui-state-active {background-color:#58595b !important; color: #58595b !important}
.ui-datepicker tbody a:hover {background-color:#58595b !important; color: #fff !important}
.ui-datepicker {color:#fff; font-family:'Work Sans', 'Arial', sans-serif; font-size:13px; }
.ui-datepicker { width: 20em;  padding:0; display: none; border-radius: 0px !important; }
.ui-datepicker .ui-datepicker-header { position: relative; padding:10px; background: #fff !important; border-radius: 0px !important;border-radius: 0px !important; border: none !important;}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next { position: absolute; top:50%; width:30px; height:30px; text-align:left; text-indent:-999em; overflow:hidden; background:none; padding:0; margin-top: -15px; border: none !important;}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-prev:hover { left:0; }
.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-next:hover { right:0; }
.ui-datepicker .ui-datepicker-prev:after{content:'';position:absolute;top:5px;width:0;height:0;left:8px;border-style:solid;border-width:10px 10px 10px 0;border-color:transparent #58595b transparent transparent}
.ui-datepicker .ui-datepicker-next:after{content:'';position:absolute;top:5px;width:0;height:0;right:8px;border-style:solid;border-width:10px 0 10px 10px;border-color:transparent transparent transparent #58595b;}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span { display: block; position: absolute; left: 50%; margin-left: -7px; top: 50%; margin-top: -8px; font-size:12px; display: none; }
.ui-datepicker-title select {display: block;}
.ui-datepicker .ui-datepicker-title {display: inline-block; vertical-align: top; width: 100%; line-height: 30px; padding: 0 15px; overflow: hidden; text-align: center; margin: 0px !important;}
.ui-datepicker .ui-datepicker-title select {background-color:#fff; border:1px solid #fff; color: #000; font-size: 14px; height:30px; margin:0; padding:0;}
.ui-datepicker select.ui-datepicker-month-year { width: 100%; }
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year { width: 45%; margin: 0 5px; float: left; border: 1px solid #58595b;}
.ui-datepicker table { width: 100%; font-size: .9em; border-collapse: collapse; margin: 0 0 .4em; border:0 none; background: #fff !important;}
.ui-datepicker th { padding: .5em .3em; text-align: center; font-weight: bold; border: 0; }
.ui-datepicker td { border: 0; padding: 1px; background-color:transparent; }
.ui-datepicker tr:nth-child(2n+1) td, .ui-datepicker-calendar .tableRowOdd {background:transparent !important;}
.ui-datepicker td span, .ui-datepicker td a { display: block; padding: .2em; text-align: right; text-decoration: none; background: none !important; border: none !important; font-weight: normal !important; }
.ui-datepicker .ui-datepicker-buttonpane { background-image: none; margin: .7em 0 0 0; padding: 0 .2em; border-left: 0; border-right: 0; border-bottom: 0; overflow:hidden; }
.ui-datepicker .ui-datepicker-buttonpane button { float: right; margin: .5em .2em .4em; cursor: pointer; padding: .2em .6em .3em .6em; width: auto; overflow: visible; } 

.ui-datepicker  tbody a { color:#58595b !important; }
.ui-datepicker-title { font-size:18px; font-weight:normal; color:#58595b; }
.ui-datepicker th { text-transform:uppercase; font-weight:normal; color:#fff; border-radius: 0;}
.ui-datepicker-prev, .ui-datepicker-next { width:25px; font-size:16px; height:25px; display:inline-block; margin:0; cursor: pointer; color:#fff; }
.ui-datepicker-prev span { background-position:-147px -27px; overflow:hidden; width:25px; height:25px; position:static; margin:5px 0 0; }
.ui-datepicker-next span { background-position:-175px -27px; overflow:hidden; width:25px; height:25px; position:static; margin:5px 0 0; }
.ui-datepicker td span {color: #58595b;}
.ui-datepicker td span, .ui-datepicker td a {text-align: center; line-height: 20px; width: 32px; height: 32px; line-height: 30px; border-radius: 50%; background: none !important; border: none !important;}
.ui-datepicker-trigger {background: inherit; padding:0; margin:0; position:absolute; right: 0px; top:50%; margin-top: -5px; border: none; cursor: pointer;}
.ui-datepicker-trigger span i {font-size: 22px; color: #cccccc;}


.ui-datepicker-calendar .ui-datepicker-today a.ui-state-highlight, .ui-datepicker-calendar .ui-datepicker-today a.ui-state-default {
    border-color: #d3d3d3 !important;
    background: #e6e6e6 !important;
    color: #555555 !important;   
}    
.ui-datepicker-calendar .ui-datepicker-today a.ui-state-highlight.ui-state-active, .ui-datepicker-calendar .ui-datepicker-today a.ui-state-default.ui-state-activa{
    border-color: #58595b !important;
    background: #58595b !important;
    color: #fff !important;
}
.ui-datepicker-calendar a.ui-state-default.ui-state-active {
    border-color: #58595b !important;
    background: #58595b !important;
    color: #fff !important;
}
.ui-datepicker a.ui-state-highlight, .ui-datepicker a.ui-state-highlight {
    border-color: #58595b !important;
    background: #58595b !important;
    color: #555555 !important;    
}    
.ui-datepicker a.ui-state-highlight.ui-state-active {
    border-color: #58595b !important;
    background: #58595b !important;
    color: #fff !important;
}
.toast-container {
    top: 20px !important;
    right: 20px !important;
}
.app-form-render-html {
    .form-updated-note {
        max-width: 965px !important;
    }
    .wrapper {
        max-width: 855px;
    }
}
.dropdown-toggle-list {
    margin-bottom: 30px;
    input {
        font-family: 'SFProDisplayRegular' !important;
        font-size: 33px !important;
        color: rgba(255,255,255,0.5);
        font-weight: normal;
        display: block;
        padding-bottom: 25px !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.25) !important;
    }
    .select-wrapper{
        .caret {
            fill: #ffffff !important;
        }
    }
}
.dropdown-toggle-list.select-toggle-location {
    input {
        color: #fff;
    }
}
.select-wrapper input.select-dropdown::-webkit-input-placeholder {
    color: rgba(255,255,255,0.5) !important;
    opacity: 1;
}
.select-wrapper input.select-dropdown::-moz-placeholder {
    color: rgba(255,255,255,0.5) !important;
    opacity: 1;
}
.select-wrapper input.select-dropdown:-ms-input-placeholder {
    color: rgba(255,255,255,0.5) !important;
    opacity: 1;
}
.select-wrapper input.select-dropdown:-moz-placeholder {
    color: rgba(255,255,255,0.5) !important;
    opacity: 1;
}
.toast-container{
    right:20px !important;
    top: 20px !important;
}
.dropdown-container {
    .dropdown-toggle-list{
        .select-wrapper {
             .caret{
                fill: #fff;
             }
        }       
    }
}
.app-form-render-html{
    height: calc(100vh - 150px); //is-for-second-sign https://app.asana.com/0/907870129842460/1207098492345245/f
    overflow-x: hidden;
    overflow-y: auto;
    
    // height: auto !important;
    // overflow: inherit !important;
    // padding-top: 124px;
    .input-field.custminput-field > label, label, input, p, pre {
        font-size: 14px !important;
    }
    .subpatientform-section{
        background: #fff !important;
    }
}
.adittech-app-form-render-html {
    position: relative;
    left: 0;
    top: -40px;
    .wrapper {
        width: auto;
    }
}
.toast-top-right {
    left: auto !important;
    right: 0;
}
.toast-container.toast-ipad-mobile-bottom-center {
    top: auto !important;
    bottom: 30px !important;
    left: 0 !important;
    right: 0 !important;
    max-width: 620px !important;
    min-width: auto !important;
    margin: 0 auto;
    padding: 0px;
    color: #fff;
    font-family: 'SFProDisplayRegular';
    font-size: 36px;
    line-height: normal;
    word-break:break-word;
    .toast-success, .ngx-toastr {
        background: #000;
        padding: 25px 45px !important;
        box-shadow: none !important;
        border-radius: 80px !important;
        display: block;
        box-shadow: none !important;
        width: 100% !important;
        margin: 0 !important;
        .toast-close-button{
            right: 0px !important;
            color: #fff !important;
            opacity: 1;
        }
    }
    span{
        display: none;
    }
    .toast-title {
        font-weight: 400;
         max-width: 650px;
         display: block;
         text-align: center;
    }
}
.toast-container {
    .ngx-toastr {
        background-image: none;
        padding: 15px !important;
    }
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 1;
}
[type="radio"] {
	&:not(:checked) {
		+ {
			span {
				color: #343434 !important;
                font-weight: 500;
			}
		}
	}
	&:checked {
		+ {
			span {
				color: #343434 !important;
                font-weight: 500;
			}
		}
	}
}

[type=radio] {
	&:checked {
		+ {
			span {
				&:after {
					border: 2px solid var(--theme-color) !important;
					left: 4px;
					top: 4px;
				}
			}
		}
	}
}
[type=radio] {
	&:checked {
		+ {
			span {
                color: #343434 !important;
                font-weight: 500;
				&:before {
					border: 2px solid var(--theme-color) !important;
                    transition: initial !important;
				}
				&:after {
					background:var(--theme-color) !important;
					left: 4px;
					top: 4px;
                    width: 8px;
                    height: 8px;
                    transition: initial !important;
				}
			}
		}
	}
}

.patientchecksign [type=radio] {
	&:checked {
		+ {
			span {
				&:after {
					border: 2px solid #939598 !important;
					left: 4px;
					top: 4px;
				}
			}
		}
	}
}
.patientchecksign [type=radio] {
	&:checked {
		+ {
			span {
                color: #002D42 !important;
                font-weight: 500;
				&:before {
					border: 2px solid #939598 !important;
                    transition: initial !important;
				}
				&:after {
                    background: #F28820 !important;
					left: 0;
                    top: 0;
                    width: 16px;
                    height: 16px;
                    transition: initial !important;
                    border: none !important;
				}
			}
		}
	}
}
.modal.dialogbox-450 {
    width: 450px !important;
}
.modal.dialogbox-768 {
    width: 768px !important;
}
.consent-view-data {
    .accept-checkbox {
        display: flex;
        align-items: center;
        margin: 0px 0px 20px 0;
        input {
            display: flex;
            align-items: center;
            position: relative !important;
            opacity: inherit !important;
            pointer-events: inherit !important;
            width: 18px;
            height: 18px !important;
            box-shadow: none !important;
            outline: none !important;
            border-bottom: none !important;
        }
        label{
            display: flex;
            align-items: center;
        }
    }
}
.treatment-preview-thanks-main {
    .treatment-preview.treatment-preview-thanks {
        .box-shadow-open.wrapper {
            padding: 0px;
        }
    }
}
.add-card-detail {
    table {
        td,th {
            padding: 0px;
        }
    }
}
.box-shadow-open {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.splash-medical-forms-scroll {
    adittech-singlecheckboxfield {
        input.with-gap {
            position: fixed !important;
        }
    }
}
.patientfoembtnbox-mobile {
    display: none !important;
}
.subpatientform-section, .verifymainblock {
    font-family: 'Roboto', sans-serif;
}
.subpatientfieldcol {
    .spacetop {
        input {
            margin: 0 !important;
            font-size: 16px !important;
        }
    }
}
.reqinvalid {
    img.ui-datepicker-trigger {
        right: 5px;
        top: 25px;
    }
}
.time-modal {
    .modal {
        transform: translate(0px, -50%) !important;
    }   
}
.medication-comment {
    .tooltip-inner {
      max-width: 300px !important;
    }
}
.patientformcustbox {
    .row {
        margin-bottom: 10px !important;
    }
    input {
        height: auto !important;
    }
    .select-wrapper {
        input.select-dropdown {
            height: auto !important;
            line-height: normal !important;
        }
    }
}
.input-field.medication-input-field.medication-name-type .dropdown-menu {
    max-height: 110px !important;
    inset: 20px auto auto 0.75rem !important;
}
ul.dropdown-content.select-dropdown.multiple-select-dropdown {
    max-height: 160px !important;
    width: 100% !important;
}
.select-custminput-field .select-wrapper {
    border-bottom: 1px solid #343434 !important;
    padding-right: 25px;
}
.select-custminput-field .select-wrapper {
    input {
        border: 0 !important;
    }
}
.login-footer-form {
    .dropdown-toggle-list ul.dropdown-content.select-dropdown {
        top: auto !important;
        max-height: 310px !important;
        height: auto !important;
        margin-top: -11px;
    }
}
.checkbox-title {
    .subpatientqueCustom {
        span {
            font-weight: 400;
        }
    }
}
.subpatientqueredio {
    label {
        span {
            font-size: 14px !important;
            font-weight: 400 !important;
        }
    }
}
.opendental-font-size {
    .personal-input {
        .personal-input-box {
            input {
                font-size: 10px !important;
            }
        }
    }
}
.app-form-render-html {
    .personal-box {
        label {
            font-size: 10px !important;
        }
    }
}
.subpatientform-section-opendental {
    .patientfoembtnbox {
        display: flex !important;
    }
}
.home-form-date {
    .datepicker-icon {
        position: relative;
        img.ui-datepicker-trigger {
            bottom: 8px;
            right: 3px;
            top: auto;
            position: absolute;
        }
    }
}
.checkin-main-page {
    padding: 0px !important;
    .home-from-checkin {
        width: 100% !important;
        margin: 0px 48px 0px 48px !important;
        height: 100vh;
        max-height: 100vh !important;
        z-index: 5;
        border-radius: 0px !important;
    }    
}
.patient-not-found-head {
    .complete-not-found {
        canvas {
            width: 300px !important;
            height: 300px !important;
        }
    }
}
.modal.dialogbox-450.dialogbox-full-width {
    max-width: 652px !important;
    width: auto !important;
    margin: 0 auto;
    padding: 0px;
    background: #fff;
}
.home-form-date {
    .datepicker-error-mess {
        position: relative;
        img.ui-datepicker-trigger {
            bottom: 15px;
        }
    }
}
// .app-form-render-html.is-for-second-sign {
//     height: auto !important; https://app.asana.com/0/1185468602336542/1207311024255912/f
//     .form-updated-note {
//         position: inherit;
//     }
// }
input[type=text]:not(.browser-default):disabled, input[type=password]:not(.browser-default):disabled,
input[type=email]:not(.browser-default):disabled, input[type=url]:not(.browser-default):disabled,
input[type=time]:not(.browser-default):disabled, input[type=date]:not(.browser-default):disabled,
input[type=datetime]:not(.browser-default):disabled, input[type=datetime-local]:not(.browser-default):disabled,
input[type=tel]:not(.browser-default):disabled, input[type=number]:not(.browser-default):disabled, [type="checkbox"] + span:not(.lever), [type="radio"]:disabled + span {
    cursor: default !important;
}
.form-updated-note.form-updated-note-devices {
    top: 0px !important;
}
.my-custom-class .tooltip-inner {
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 4px; 
}
.dialogbox-768.dialogbox-full-width {
    height: inherit !important;
    max-height: fit-content !important;
    overflow: hidden;
    background: #fff;
}
.select-prefill-datepicker {
    z-index: 1056 !important
}
@media only screen and (max-width:1200px) {
    .splash-medical-forms-scroll {
        .subpatientformmain-section {
            .wrapper {
                width: 100%;
            }
        }
    }
    .app-form-render-html {
        .wrapper {
            width: 100%;
        }
    }
    .modal.dialogbox-768.dialogbox-full-width {
        width: 100% !important;
    }
   
}
@media only screen and (max-width:1366px) {
    .subpatientform-section {
        padding: 40px 0;
        // p, span, label, h1,h2, h3, h4, h5, strong, pre , input {
        //     font-size: 12px !important;
        // }
        // .subpatientfieldcol, .subpatientqueredio {
        //     .spacetop, label {
        //         input, span {
        //             font-size: 12px !important;
        //         }
        //     }
        // }
        // .signbtnpopup, .subpatientheadtitle {
        //     a, strong {
        //         font-size: 16px !important;
        //     }
        // }
        // .subpatientgridcheckbox {
        //     tr {
        //         td, th {
        //             font-size: 12px !important;
        //         }
        //     }
        // }
        .timepicker-text-container {
            .timepicker-display-column {
                span.timepicker-span-minutes, .timepicker-span-hours {
                    font-size: 4rem !important;
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .treatment-preview {
        width: 100% !important;
    }
    .patientfoembtnbox {
        display: none !important;
    }
    .patientfoembtnbox.opendental-submit { // https://app.asana.com/0/1177963020146673/1209172887931053/f
        display: block !important;
        margin: 0 auto;
        text-align: center;
        .submit {
            text-align: center;
            display: inline-block;
        }
    }
    .patientfoembtnbox.patientfoembtnbox-mobile {
        display: flex !important;
    }
    .patientfoembtnbox.patientfoembtnbox-mobile {
        flex-direction: column;
        .pagenationbox {
            display: block !important;
            p {
                font-size: 16px;
            }
        }
        .previous, .bottom.submitBtn {
            display: inline-block !important;
            margin: 0px 6px !important;
        }
        .patientfoembtnbox-mobile-center{
            margin: 10px 0px;
        }
        a.back-text {
            color: #25A8E0 !important;
            width: auto;
        }
    }
    .opendental-font-size {
        width: inherit !important;
        .patientfoembtnbox {
            margin-top: 20px;
        }
    }
    .opendental {
        .parentDiv {
            left: 0px !important;
            width: 100% !important;
            overflow: auto !important;
        }
    }
    .app-form-render-html.is-for-second-sign {
        .subpatientformmain-section {
            .signbelow {
                top: 70px !important;
                left: -75px !important;
                width: 80px !important;
                height: 60px !important;
                p {
                    font-size: 13px !important;
                }
                img {
                    bottom: -10px !important;
                    width: 40px;
                }
            }   
        }
    }
}
@media only screen and (max-width:991px) {
    .toast-container.toast-ipad-mobile-bottom-center {
        font-size: 23px;
        line-height: normal;
        max-width: 500px !important;
        right: 0 !important;
        margin: 0 auto !important;
        .toast-success, .ngx-toastr {
            background: #000;
            padding: 22px 25px !important;
            .toast-close-button {
                right: 0px !important;
                color: #fff !important;
                opacity: 1;
            }
        }
        span {
            display: none;
        }
    }
    .treatment-preview {
        width: auto !important;
    }
    .login-footer{
        .dropdown-toggle-list {
            input {
                font-size: 23px !important;
                padding-bottom: 15px !important;
            }
        }
    }
    .treatment-preview {
        .preview-options {            
            .preview-options-tooltip {
                top: -63px !important;
                left: -25px !important;
                right: 0;
                margin: 0 auto;
                width: 218px !important;
                p {
                    font-size: 12px;
                    width: 189px;
                    line-height: normal !important;
                    top:-3px;
                }
                &:after {
                    background: url(/assets/img/mobile-img.png) no-repeat 0 0 !important;
                    width: 218px !important;
                    height: 65px !important;
                }
            }
        }
    }
    .verifymaininnerblock {
        height: calc(100vh - 250px) !important
    }
    .patientfoembtnbox {
        display: none !important;
    }
    .patientfoembtnbox.patientfoembtnbox-mobile {
        display: flex !important;
    }
    .patientfoembtnbox.patientfoembtnbox-mobile {
        flex-direction: column;
        .pagenationbox {
            display: block !important;
        }
        .previous, .bottom.submitBtn {
            display: inline-block !important;
            margin: 0px 6px !important;
        }
        .patientfoembtnbox-mobile-center{
            margin: 10px 0px;
        }
        a.back-text {
            color: #25A8E0 !important;
            width: auto;
        }
    }
    .login-footer-form {
        .dropdown-toggle-list ul.dropdown-content.select-dropdown {
            max-height: 295px !important;
        }
    }
    .patient-not-found-head {
        .complete-not-found {
            canvas {
                width: 200px !important;
                height: 200px !important;
            }
        }
    }
    .app-form-render-html.is-for-second-sign {
        .subpatientformmain-section {
            .signbelow {
                top: 0px !important;
                left: 15px !important;
                width: 80px !important;
                height: 60px !important;
                img {
                    bottom: -10px !important;
                    width: 40px;
                }
            }   
        }
    }
}
@media only screen and (max-width:767px) {
    .login-footer {
        .dropdown-toggle-list {
            input {
                font-size: 18px !important;
                padding-bottom: 10px !important;
            }
        }
    }
    .toast-container {
        right:20px !important;
        top: 20px !important;
        .ngx-toastr{
            float: right;
        }
    }
    .app-form-render-html {
        .wrapper {
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .adittech-app-form-render-html {
        .opendental {
            .parentDiv {
                position: relative;
            }
        }
        .wrapper {
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    .toast-container.toast-ipad-mobile-bottom-center {
        font-size: 18px;
        width: 95% !important;
        max-width: 100% !important;
        text-align: center;
        margin: 0px auto;
        .toast-success, .ngx-toastr {
            background: #000;
            padding: 20px 25px !important;
            display: inline-block;
            box-shadow: none !important;
            width: auto !important;
            margin: 0 auto !important;
            float: none;
        }
        span{
            display: none;
        }
    }
    .billing-details-title {
        h3 {
            margin: 20px 0px 5px !important;
        }
    }
    .alert-confirm-popup {
        .dialogbox-block {
            padding: 0px !important;
        }
    }
    .treatment-preview {
        width: 100% !important;
        padding: 0px 5px;
        .wrapper {
            width: 100% !important;
            padding: 0px;
            max-width: 100% !important;
        }
        .select-payment-method {
            .payment-method {
                .add-card {
                    .addcarf-left {
                        margin-top: 0!important;
                        padding: 0!important;
                        width: 100%!important;
                        flex-direction: column;
                        .add-card-data{
                            margin: 15px 0;
                            width: 100%!important;
                        }
                    }
                }
            }
        }
        .consent-view {
            .consent-view-data {
                .signature-main {
                    display: flex;
                    flex-direction: column;
                    .signature-view {
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .phase-preview {
            .total-fee-estimate {
                .total-fee-head {
                    padding: 15px 12px !important;
                    h2 {
                        font-size: 28px !important;
                        margin: 0px !important;
                    }
                }
            }
        }
        .select-payment-options {
            .select-payment {
                .select-payment-option {
                    margin-bottom: 15px!important;
                }
                .select-payment-block {
                    display: block !important;
                }
                .ordivide{
                    margin-bottom: 10px;
                }
            }
        }
    }
    .treatment-preview.treatment-preview-thanks-main {
        .treatment-preview.treatment-preview-thanks {
            padding: 0;
        }
        .add-card-detail {
            margin-top: 0px !important
        }
    }
    .tooth-icon-inner {
        width:95%;
        padding: 36px 10px 24px 10px !important;
        .tooth-icon-top {
            .tooth-icon-item {
                img {
                    object-fit: contain;
                    height: 50px;
                }
            }
        }
        .tooth-icon-bottom {
            .tooth-icon-item {
                img {
                    object-fit: contain;
                    height: 50px;
                }
                span {
                    margin:10px 0px 0px 0px;
                }
            }
        }
        .tooth-icon-border {
            label {
                font-size: 12px !important;
                line-height: normal !important;
            }
        }
    }
    .add-card-detail {
        margin-top: 20px !important;
        h2 {
            margin: 0px 0px 10px !important;
        }
        
    }
    .add-card {
        .add-card-orangetabs-plans-block {
            display: flex;
            flex-direction: column;
        }
        .addcarf-left {
            padding-right: 0px !important;
        }
        .addcarf-right {
            padding-left: 0px !important;
        }
        .addcarf-right.addcarf-right-half {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0px;
        }
        .add-card-detail-title{
            h2 {
                margin: 0px 0px 5px !important;
                display: block;
            }
        }
    }
    .signature-text, .doctor-signature, .signature-view, .input-background {
        width: 100%;
    }
    .subpatientformmain-section {
        .wrapper {
            width: 100%;
        }
    }
    .wrapper {
        width: 100% !important;
    }
    .treatment-preview {
        .preview-options {
            box-shadow: none !important;
            .nav-tabs {
                .nav-item {
                    width: auto;                    
                    .nav-link {
                        padding: 0px 25px !important;
                        box-shadow: inset 0px -3px 6px -3px #aeaeae;
                    }
                }
            }
        }
        .preview-options-tooltip {
            top: -63px !important;
            left: -35px !important;
            right: 0;
            margin: 0 auto;
            width: 218px !important;
            p {
                font-size: 12px;
                width: 189px;
                line-height: normal !important;
                top:-3px;
            }
            &:after {
                background:url(/assets/img/mobile-img.png) no-repeat 0 0 !important;
                width:218px !important;
                height:65px !important;
            }
        }
    }
    .patientlogobox {
        img {
            max-height: 80px !important;
        }
    }
    .patientformheader {
        padding:34px 0px !important;
    }
    .patientfoembtnbox, .submitBtn {
        a {
            padding: 11px 0px !important;
            font-size: 16px !important;
            margin: 0px !important;
            display: inline-block;
            width: 108px;
            text-align: center;
        }
    }
    .patientfoembtnbox {
        display: none !important;
    }
    .patientfoembtnbox.patientfoembtnbox-mobile {
        display: flex !important;
    }
    .patientfoembtnbox.patientfoembtnbox-mobile {
        flex-direction: column;
        .pagenationbox {
            display: block !important;
            p {
                font-size: 14px;
            }
        }
        .previous, .bottom.submitBtn {
            display: inline-block !important;
            margin: 0px 6px !important;
        }
        .patientfoembtnbox-mobile-center{
            margin: 10px 0px 0;
        }
        a.back-text {
            color: #25A8E0 !important;
            width: auto;
        }
    }
    .app-form-render-html.is-for-second-sign {
        .subpatientformmain-section {
            .signbelow {
                top: 10px !important;
            }
            .patientformheader {
                padding: 60px 0px 30px 0px !important;
            }
        }
    }
    .form-updated-note-mobile {
        .subpatientformmain-section {
            padding: 40px 0px 0px 0px;
        }
    }
    .active-btn {
        margin-top: 50px;
        button.btn.btn-primary, button.btn.btn-primary.border-light-line {
            font-size: 16px !important;
            line-height: normal !important;
            padding: 15px !important;
            border-radius: 16px !important;
        }
    }
    .select-patient-forms {
        .pre-fill-header {
            .pre-fill-header-text {
                font-size: 20px;
            }
        }
    }
}
.back-btn {
    display: inline-block;
    a.back-text {
        background: none !important;
        color: #25A8E0 !important;
        cursor: pointer;
        display: inline-block;
        font-size: 18px !important;
        font-weight: 500;
        line-height: normal;
        margin: 0px 0px 0px 0px;
        padding: 14px !important;
        
    }
}
.select-custminput-field {
    ul.dropdown-content.select-dropdown {
        top: 20px !important;
        height: auto !important;
    }
    .dropdown-content li {
        height: auto !important;
        min-height: auto !important;
    }
    .dropdown-content li span {
        padding: 7px 15px !important;
        line-height: normal !important;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
    }
    .select-dropdown.dropdown-content li:hover {
        background-color: rgba(0,0,0,.04)!important;
    }
    .select-wrapper .select-dropdown.dropdown-content li:hover span {
        color: #343434 !important;
    }
}
.dropdown-content.select-dropdown.multiple-select-dropdown {
    li {
        span {
            p {
                margin: -2px 0px 0px 15px;
                font-weight: normal;
            }
        }
    }    
}
.select-custminput-first-menu {
    .select-wrapper {
        margin-top: 4px;
    }
    .ng-select {
        border-bottom: 1px solid #343434;
        padding: 0px !important;
        .ng-select-container {
            min-height: auto !important;
            font-weight: 500;
            .ng-value-container {
                padding: 0;
                border: none;
            }
            &:after {
                border: none !important;
            }
        }
    }
    input {
        border: none !important;
        padding: 0px !important;
        cursor: inherit !important;
        color: #343434 !important;
        outline: none !important;
        color: transparent;
        text-shadow: 0 0 0 #2196f3;
        -webkit-user-select: none !important;
        user-select: none !important;
        width: 0 !important;
        padding: 0 !important;
        &:focus {
            box-shadow: none !important;
            outline: none;
        }
    }
    .ng-clear-wrapper {
        display: none;
    }
    .ng-dropdown-panel.ng-select-bottom {
        top: 100% !important;
        margin-top: 1px;
    }
    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                line-height: normal !important;
                min-height: inherit !important;
                padding: 7px 15px !important;
            }
            .ng-option.ng-option-selected {
                background: rgba(0,0,0,0.03);
                color: #f28820;
            }
        }
    }
    .ng-dropdown-panel-items.scroll-host {
        max-height: 95px !important;
    }
}
.select-custminput-first-multi-menu {
    .ng-select {
        border-bottom: 1px solid #343434;
        padding: 0px !important;
        .ng-select-container {
            min-height: auto !important;
            .ng-value-container {
                padding: 0;
                border: none;
            }
            &:after {
                border: none !important;
            }
        }
    }
    input {
        border: 1px solid #5a5a5a;
        padding: 0px !important;
        color: #343434 !important;
        outline: none !important;
        color: transparent;
        text-shadow: 0 0 0 #2196f3;
        -webkit-user-select: none !important;
        user-select: none !important;
        width: 0 !important;
        padding: 0 !important;
        -webkit-appearance: none;
        appearance: none;
        opacity: .5;
        position: relative;
        // border: none !important;
        // cursor: inherit !important;
        // color: #343434 !important;
        // outline: none !important;
        // -webkit-user-select: none !important;
        // user-select: none !important;
        // width: 15px !important;
        // height: 15px !important;
        // opacity: 1 !important;
        // pointer-events: inherit !important;
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            opacity: 1;
        }
        &:checked {
            background-color: transparent;
            opacity: 1;
            &:before {
                content: "";
                position: absolute;
                right: 50%;
                top: 50%;
                width: 6px;
                height: 10px;
                border: solid #343434;
                border-width: 0 2px 2px 0;
                margin: -1px -1px 0 -1px;
                transform: rotate(45deg) translate(-50%, -50%);
                z-index: 2;
            }
        }
        &:checked {
            &:after {
                position: absolute;
                top: -1px;
                left: -1px;
                content: "";
                border: 2px solid #f7f7f7;
                height: 15px;
                width: 15px;
            }
        }
    }
    .ng-clear-wrapper {
        display: none;
    }
    .ng-select.ng-select-multiple {
        .ng-select-container {
            min-height: auto !important;
            .ng-value-container {
                .ng-value {
                    color: #343434;
                    background: transparent !important;
                }
            }
        }
    }
    .ng-dropdown-panel.ng-select-bottom {
        top: 100% !important;
        margin-top: 1px;
    }
    span.ng-value-icon.left {
        display: none;
    }
    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                line-height: normal !important;
                min-height: inherit !important;
                padding: 7px 15px !important;
            }
            .ng-option.ng-option-selected {
                background: rgba(0,0,0,0.03);
                color: #f28820;
            }
        }
    }
    .ng-select.ng-select-multiple {
        .ng-select-container.ng-has-value {
            .ng-arrow-wrapper {
                border: none !important;
            }
        }
        .ng-select-container {
            .ng-value-container {
                .ng-value {
                    margin: 0px 0px 0px 0px;
                    padding-left: 0;
                    padding-top: 0px;
                    line-height: normal;
                }
            }
        }
    }
    .ng-dropdown-panel-items.scroll-host {
        max-height: 95px !important;
        input {
            margin: 0px !important;
            font-size: 14px !important;
            opacity: 1 !important;
            pointer-events: inherit !important;
            width: 15px !important;
            height: 15px !important;
            outline: none !important;
            -webkit-user-select: none !important;
            user-select: none !important;
            &:focus {
                box-shadow: none !important;
            }
        }
        p.item-key {
            display: inline-block;
            padding-left: 23px;
        }
    }
    .ng-select-multiple {
        .ng-select-opened {
            .ng-select-container.ng-has-value {
                p {
                    padding-left: 0;
                }
            }
            input {
                display: none;
            }
        }
    }
}
.cursor-pointer-none {
    cursor: default !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
.tooltip-min-width310 {
    .tooltip-inner {
        min-width: 310px !important;
        max-width: 300px !important;
        width: 300px !important;
        font-size: 14px;
        font-family: 500;
        font-family: 'Work Sans', 'Arial', sans-serif !important;
        background-color: #000 !important;
        opacity: 0.8;
    }
    .tooltip-arrow {
        display: none;
        
    }
}
.modal.dialogbox-1050 {
    width: 1050px;
    border-radius: 4px;
}
@media screen and (max-width: 767px) { 
    .select-wrapper {
        .select-dropdown.dropdown-content {
            max-height: 257px !important;
            li.selected {
                min-height: inherit !important;
                line-height: normal !important;
                span {
                    line-height: normal !important;
                    padding: 7px 16px !important;
                }
            }
        }
    }
    .login-footer-form {
        .select-wrapper {
            .select-dropdown.dropdown-content {
                max-height: 257px !important;
                li.selected {
                    min-height: auto !important;
                    line-height: normal !important;
                    span {
                        line-height: normal !important;
                        padding: 14px 16px !important
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:479px) {
    .toast-bottom-center {
        font-size: 20px;
    }
    .modal.dialogbox-450{
        width: 95% !important;
    }
    
    .treatment-preview{
        padding: 0px 5px;
        .preview-options {
            .nav{
                li{
                    display:inline-block;
                    width: 50% !important;
                    .nav-link{
                        padding:5px 10px;
                        display: inline-block;
                        width:100%;
                        
                    }
                }
            }
        }
        .treatment-preview {
            .select-payment-method {
                .payment-method {
                    .add-card {
                        .addcarf-left {
                            .add-card-data {
                                &:first-child {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .treatment-preview-thanks-page-main {
        .treatment-preview-thanks-page-img {
            img {
                width: 92px !important;
            }
        }
        .treatment-preview-thanks-page-info {
            .treatment-preview-thanks-top{
                display: none !important;
            }
            padding-left: 10px !important;
        }
    }
    .treatment-preview-thanks-page {
        flex-direction: column;
        .mobile-treatment-preview-thanks-page {
            display: flex !important;
            width: 100%;
            justify-content: space-between;
            .treatment-preview-thanks-top {
                margin-bottom: 0px !important;
                .headlogo {
                    img {
                        width: 40px !important;
                        height: 40px !important;
                    }                    
                }
                h1 {
                    font-size: 18px;
                    margin: 0 0 0 10px;
                }
            }
        }
        .treatment-preview-thanks-page-info, .transaction-text {
            p {
                font-size: 14px!important;
                padding: 0!important;
            }
        }
    }
    .headerpart-main {
        .headerpart.treatment-preview-thanks-top {
            display: none !important;
        }
    }
    .tooth-icon-section{
        .tooth-icon-inner{
            padding: 30px 5px 15px 5px !important;
            width: 90%;
            .tooth-icon-border{
                label{
                    font-size:10px !important;
                    line-height: normal !important;
                }
            }
            .tooth-icon-top{
                .tooth-icon-item{
                    span{
                        font-size:10px !important;
                    }
                    
                    img{
                        height:35px;
                        object-fit: contain;
                    }
                }
            }
            .tooth-icon-bottom{
                .tooth-icon-item{
                    span{
                        font-size:10px !important;
                    }
                    img{
                        height:35px;
                        margin-top:5px;
                        object-fit: contain;
                    }
                } 
            }
            &.tooth-diagram-icon-inner {
                .tooth-diagram-head {
                    .tooth-diagram-row {
                        .tooth-diagram-col {
                            .tooth-diagram-grid {
                                .tooth-icon-item-text {
                                    span{
                                        font-size:10px !important;
                                    }
                                    img {
                                        height:35px !important;
                                        object-fit: contain !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
    .select-payment-method {
        .payment-method {
            .payment-method-credit-cash {
                label {
                    width: 100%;
                    flex-direction: column;
                    display: flex;
                }
                .label-credit-card {
                    width: 115px;
                }
                .label-check {
                    width: 80px;
                }
                .label-cash {
                    width: 70px;
                }
            }
        }
    }
    .treatment-preview.treatment-preview-thanks-main {
        .treatment-preview-thanks {
            .select-payment-options {
                padding: 0px 10px !important;
            }
            .select-payment-method {
                padding: 0px 10px !important;
            }
            .phase-preview {
                padding: 0px 10px !important;
            }
            .consent-view {
                padding: 0px 10px !important;
            }
        }
    }
    .treatment-banner {
        .treatment-banner-data {
            .treatment-banner-data-table {
                .carrier-data {
                    .carrier-data-item {
                        padding: 0px 0px 10px;
                        width: 80px;
                        margin-right: 5px !important;
                        p {
                            white-space: normal;
                            word-break: break-word;
                            width: 80px !important;
                        }
                    }
                }
            }
        }
    }
    .treatment-preview {
        .preview-options {
            .preview-options-tooltip {
                left: 0px !important;
            }
        }
    }
    .toast-container.toast-ipad-mobile-bottom-center {
        font-size: 14px;
        width: 100%;
        text-align: center;
        bottom: 30px !important;
        top: auto !important;
        .toast-success, .ngx-toastr {
            background: #000;
            padding: 15px 25px !important;
        }
    }
    .patientfoembtnbox.patientfoembtnbox-mobile {
        .patientfoembtnbox-mobile-center {
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            .bottom.submitBtn {
                margin: 0px 6px 12px !important;
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .modal.dialogbox-450.dialogbox-full-width { 
        width: 90% !important;
    }
}

@media screen and (max-width: 414px) and (min-width: 375px){
    .tooth-icon-section{
        .tooth-icon-inner {
            .tooth-icon-top {
                .tooth-icon-item {
                    span{
                        font-size:9px !important;
                        margin-top:5px !important;
                    }
                    img{
                        height:41px;
                    }
                }
            }
            .tooth-icon-bottom {
                .tooth-icon-item {
                    span{
                        font-size:9px !important;
                        margin-bottom:3px !important;
                    }
                    img{
                        height:43px;
                    }
                }
            }
            .tooth-icon-border {
                label{
                    font-size: 9px !important;
                    left: -5px;
                    position: relative;
                    &:last-child{
                        right: -5px;
                        left: auto;
                        position: relative;
                    }
                }
                &::after{
                    width: calc(100% - 55px) !important;
                }
                &.tooth-diagram-icon-inner {
                    .tooth-diagram-head {
                        .tooth-diagram-row {
                            .tooth-diagram-col {
                                .tooth-diagram-grid {
                                    .tooth-icon-item-text {
                                        span{
                                            font-size:9px !important;
                                            margin-bottom:3px !important;
                                        }
                                        img {
                                            height: 43px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }  
}
@media screen and (max-width: 360px){
    .tooth-icon-section{
        .tooth-icon-inner {
            .tooth-icon-top {
                .tooth-icon-item {
                    span{
                        font-size:9px !important;
                        margin-top:5px !important;
                    }
                    img{
                        height:34px;
                    }
                }
            }
            .tooth-icon-bottom {
                .tooth-icon-item {
                    span{
                        font-size:9px !important;
                        margin-bottom:3px !important;
                    }
                    img{
                        height:34px;
                    }
                }
            }
            .tooth-icon-border {
                label{
                    font-size: 9px !important;
                    left: -5px;
                    position: relative;
                    &:last-child{
                        right: -5px;
                        left: auto;
                        position: relative;
                    }
                }
                &::after{
                    width: calc(100% - 55px) !important;
                }
            }
            &.tooth-diagram-icon-inner {
                .tooth-diagram-head {
                    .tooth-diagram-row {
                        .tooth-diagram-col {
                            .tooth-diagram-grid {
                                .tooth-icon-item-text {
                                    span{
                                        font-size:9px !important;
                                        margin-bottom:3px !important;
                                    }
                                    img {
                                        height: 34px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .treatment-preview{
        .preview-options{
            .preview-options-tooltip{
                left:0px !important;
            }
        }
    }
}